.container {
  height: 130px;
  width: 100%;
  position: absolute;
  background-color: #151b8d;
}
.contact {
  width: 270px;
  height: 50px;
  margin: 0 auto;
  top: 15%;
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #ffff;
}
.contact_info {
  margin-top: 20px;
  text-align: center;
}
.contact_info > div {
  color: #ffff;
  font-size: 19px;
}
.contac_detailed {
  display: flex;
  align-items: center;
  justify-content: center;
}
.location {
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px !important;
  margin-top: 12px !important;
}
.streetName {
  display: inline-block;
  margin-left: 20px !important;
}
.footerlogo {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .location {
    flex-direction: column;
  }
}
