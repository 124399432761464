.container {
  background-color: #ffffff;
  height: 100vh;
}

.navbarlogo {
  /* position: absolute; */
  position: absolute;
  left: 7%;
  top: 2%;
  z-index: 222;
  width: 200px !important;
  height: 36px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.myswiper {
  width: 100%;
}
.image {
  width: 100%;
  height: 99.8vh !important;
  object-fit: fill;
}

.companyName {
  position: absolute;
  top: 40%;
  left: 7%;
  font-size: 45px;
  z-index: 998;
  color: #151b8d;
  font-weight: 500;
}

.buttons {
  /* position: relative; */
  position: absolute;
  z-index: 999;
  right: 10%;
  top: 2%;
  display: flex;

  /* background-color: green; */
}

.buttons > a {
  text-decoration: none;
}

.contact {
  /* position: absolute; */
  /* top: 20%; */
  z-index: 998;
  /* right: 8%; */
  width: 110px;
  height: 40px;
  background-color: #dc3545;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
}

.contact:hover {
  border: 1px solid #dc3545;
  /* color: #fff; */
  color: #dc3545;
  background-color: #fff;
}
.tender {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 40%; */
  z-index: 997;
  top: 12%;
  /* right: 8%; */
  width: 110px;
  height: 40px;
  background-color: #dc3545;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  margin-left: 5px;
}

.tender:hover {
  border: 1px solid #dc3545;
  color: #dc3545;
  background-color: #fff;
}
.social_icons {
  position: absolute;
  display: inline-block;
  top: 2%;
  left: 45%;
  z-index: 999;
  width: 200px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  color: #dc3545;
}

@media screen and (max-width: 767px) {
  .social_icons {
    top: 3%;
    left: 60%;
    width: 135px;
    height: 38px;
  }
  .navbarlogo {
    left: 5%;
    top: 2%;
    width: 170px !important;
    height: 35px !important;
  }

  /* .tender {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 997;
    top: 35%;
    left: 37%;
    width: 130px;
    height: 50px;
    background-color: #dc3545;
    border-radius: 12px;
    color: #ffffff;
    cursor: pointer;
  } */

  /* .contact {
    position: absolute;
    top: 50%;
    left: 40%;
    z-index: 998;
    right: 8%;
    width: 110px;
    height: 50px;
    background-color: #dc3545;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    color: #ffffff;
    cursor: pointer;
  } */
  .companyName {
    font-size: 30px !important;
    font-weight: 400;
    top: 35%;
    text-align: center !important;
    right: 10% !important;
  }
  .image {
    height: 600px !important;
    background-position: center;
    object-fit: cover;
    /* object-fit: contain; */
  }

  .buttons {
    /* position: relative; */
    position: absolute;
    z-index: 999;
    left: 2%;
    top: 75%;
    display: flex;

    /* background-color: green; */
  }
  .tender {
    margin-left: 15px;
  }
}
