.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_contact_option {
  width: 100%;
  text-align: center;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

ul > li {
  font-size: 16px;
}
.title {
  font-size: 20px !important;
}
.apply {
  font-size: 18px;
}
.info {
  width: 100%;
  height: 80px;
  background-color: #f7f9fc;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}
.locotion {
  margin-left: 10px !important;
}
.info_locotion {
  width: 300px;
}

.info > div:nth-child(1) {
  margin-top: 20px !important;
}
.number {
  margin-left: 10px;
}
.contact {
  width: 150px;
  height: 50px;
  background-color: #dc3545;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  border-style: none;
  margin-bottom: 15px;
}

.contact:hover {
  border: 1px solid #dc3545;
  color: #dc3545;
  background-color: #fff;
}

.navbarlogo {
  /* position: absolute; */
  position: absolute;
  left: 7%;
  top: 2%;
  z-index: 222;
  width: 185px !important;
  height: 33px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-weight: 100 !important;
}
.message {
}
.contact__option {
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  width: 450px;
  margin-top: 30px !important;
}

.contact__option_icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  color: #dc3545;
}
.contact__option > a {
  text-decoration: none !important;
}

@media screen and (max-width: 767px) {
  .phoneTitle {
    margin-top: 65px;
  }
  .mainHeader,
  .title,
  .apply {
    margin-left: 15px;
  }
}
