.tenderConTainer {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NameSername {
  margin-top: 30px !important;
  width: 600px !important;
  height: 60px !important;
  color: #151b8d;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 21px;
}

.detailedInfo {
  margin-top: 30px !important;
  width: 450px !important;
  height: 50px !important;
  background-color: #fff;
  color: #151b8d;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 21px;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  color: #ffff;
  font-weight: 100 !important;
}
.message {
  color: #ffff;
}
.contact__option {
  background-color: #151b8d;
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  width: 450px;
  margin-top: 30px !important;
}

.contact__option_icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  text-decoration: none;
}

.locationTender {
  /* width: 500px; */
}

.mainPage {
  width: 300px;
  height: 50px;
  background-color: #151b8d;
  margin-top: 40px;
  border-radius: 12px;
  color: #fff;
  font-size: 18px;
}
.mainPage:hover {
  background-color: #fff;
  border: 1px solid #151b8d;
  color: #151b8d;
  cursor: pointer;
}

/* Map start */

.App {
  height: 100vh;
  width: 100%;
}

.circle {
  background: #d83b01;
  border-radius: 50%;
  color: #fff;
  height: 2.5em;
  position: relative;
  width: 2.5em;

  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.App {
  text-align: center;
}
.navbarlogo {
  /* position: absolute; */
  position: absolute;
  left: 7%;
  top: 2%;
  z-index: 222;
  width: 185px !important;
  height: 33px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.contact__option {
  color: white;
}
@media screen and (max-width: 767px) {
  .detailedInfo {
    width: 400px !important;
  }
  .contact__option {
    background-color: #151b8d;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 360px !important;
    margin-top: 30px !important;
  }

  .contact_mobile {
    display: none !important;
  }

  .NameSername {
    /* margin-top: 30px !important; */
    margin: 60px 20px 0 20px !important;
    /* margin-top: 50px !important; */
    /* width: 500px !important; */
    height: 70px !important;
    color: #151b8d;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-size: 21px;
    text-align: center;
    width: 380px !important;
  }
  .footer {
    width: 100%;
  }
}
